
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
    name: "KToolbar",
    props: {
        breadcrumbs: Array,
        title: String,
    },
    components: {
        // KTNotificationsMenu,
    },
    data() {
        return {
            username: "",
            perm_pos: "" as any,
            isStaging: false,
        };
    },
    mounted() {
        var token_infos;
        if (localStorage.getItem("id_token")) {
            token_infos = JwtService.parseToken(localStorage.getItem("id_token"));
            this.username =
                token_infos.firstname.charAt(0).toUpperCase() +
                token_infos.firstname.slice(1) +
                " " +
                token_infos.lastname.charAt(0).toUpperCase() +
                token_infos.lastname.slice(1);
        }        
        if (process.env.VUE_APP_API_URL.includes("http://51.138.120.205/")) this.isStaging = true;
        this.perm_pos = localStorage.getItem("perm_pos");
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
        };

        return {
            toolbarWidthFluid,
            signOut,
        };
    },
});
