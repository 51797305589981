
import { defineComponent, onMounted, onUpdated } from "vue";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
    name: "KTAside",
    components: {
        KTMenu,
    },
    props: {
        lightLogo: String,
        darkLogo: String,
    },
    data() {
        return {
            perm_pos: "" as any,
        };
    },
    setup() {
        onMounted(() => {
            ToggleComponent.reinitialization();
        });

        onUpdated(() => {
            ToggleComponent.bootstrap();
        });

        return {
            asideTheme,
        };
    },
    mounted() {
        this.perm_pos = localStorage.getItem("perm_pos");
    },
});
