
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
    name: "kt-menu",
    components: {},
    data() {
        return {
            perm_pos: "" as any,
        };
    },
    setup() {
        const route = useRoute();
        const scrollElRef = ref<null | HTMLElement>(null);

        onMounted(() => {
            ScrollComponent.reinitialization();
            MenuComponent.reinitialization();
            if (scrollElRef.value) {
                scrollElRef.value.scrollTop = 0;
            }
        });

        const hasActiveChildren = (match) => {
            return route.path.indexOf(match) !== -1;
        };

        return {
            hasActiveChildren,
            MainMenuConfig,
            asideMenuIcons,
            version,
        };
    },
    mounted() {
        this.perm_pos = localStorage.getItem("perm_pos");
    },
    methods: {
        closeAccordion() {
            const show = document.querySelector(".show");
            const hover = document.querySelector(".hover");
            const menuSub = document.getElementsByClassName("menu-sub") as any;

            if (show) {
                show.classList.remove("show");
            }
            if (hover) {
                hover.classList.remove("hover");
            }
            if (menuSub) {
                for (let i = 0; i < menuSub.length; i++) menuSub[i].classList.remove("show");
            }
        },
    },
});
